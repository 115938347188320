<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="clientsList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage" class="p-datatable-users" data-key="bd1" :rowHover="true"
                    v-model:expandedRows="expandedRows" v-model:selection="selectedusers"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Client List</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Client" icon="pi pi-plus" @click="addNewUserDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Party Name" headerStyle="width: 26%">
                        <template #body="{ data }">
                            <div class="p-d-flex p-ai-center">
                                <div class="p-mr-3">
                                    <span v-if="data.maa6 != null && data.maa6 != ''">
                                        <img :src="'https://storage.googleapis.com/' + storage_path + '/Client/Logo/' + data.maa6"
                                            width="50" style="vertical-align: middle" />
                                    </span>
                                    <span v-if="data.maa6 == null || data.maa6 == ''">
                                        <img src="/assets/layout/images/widgets/political-icon.png" width="50"
                                            style="vertical-align: middle" />
                                    </span>
                                </div>
                                <div>
                                    <div class="p-text-capitalize" v-tooltip.right="'Client Name'">{{ data.maa2 ?
                                        data.maa2 : 'N/A' }}</div>
                                    <small class="muted-text p-text-capitalize p-d-flex p-my-1"
                                        v-tooltip.right="'Party Name'">{{ data.maa4 ? data.maa4 : 'N/A' }}</small>
                                    <small class="muted-text p-text-capitalize"
                                        v-tooltip.right="'Activated Date'">{{ format_timestamp(data.maa69) }}</small>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Vidhansabha /Loksabha" headerStyle="width: 33%">
                        <template #body="{ data }">
                            <div>
                                <div class="p-text-capitalize">
                                    <span v-tooltip.right="'Vidhan Sabha'">
                                        <span v-if="data.maa16">
                                            <span v-for="(value, index) in data.maa16.split(',')" :key="index">
                                                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan" :label="value" />
                                            </span>
                                        </span>
                                        <span v-else>
                                            N/A
                                        </span>
                                    </span>
                                </div>
                                <Divider class="p-mt-1 p-mb-2" />
                                <div class="p-text-capitalize p-mt-1">
                                    <span v-tooltip.right="'Lok Sabha'">
                                        <span v-if="data.maa14">
                                            <span v-for="(value, index) in data.maa14.split(',')" :key="index">
                                                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-lok" :label="value" />
                                            </span>
                                        </span>
                                        <span v-else>
                                            N/A
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Contact Details" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>
                                <div>+91 {{ data.maa17 ? data.maa17 : 'N/A' }}</div>
                                <div class="p-mt-1"><small class="muted-text"> {{ data.maa18 ? data.maa18 : 'N/A'
                                }}</small></div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 9%">
                        <template #body="{ data }">
                            <span v-if="data.maa37 == 0" :class="'user-badge status-active'">Active</span>
                            <span v-if="data.maa37 == 1" :class="'user-badge status-temp-suspend'">Soft
                                Suspension</span>
                            <span v-if="data.maa37 == 2" :class="'user-badge status-temp-suspend'">Temporary
                                Suspension</span>
                            <span v-if="data.maa37 == 3" :class="'user-badge status-temp-suspend'">Suspended</span>
                            <span v-if="data.maa37 == 4" :class="'user-badge status-per-suspend'">Deletion Notice
                                Period</span>
                            <span v-if="data.maa37 == 5" :class="'user-badge status-per-suspend'">Permanent
                                Suspended</span>
                            <span v-if="data.maa37 == 6" :class="'user-badge status-per-suspend'">Deleted</span>
                            <span v-if="data.maa37 == 7" :class="'user-badge status-temp-suspend'">Under
                                Maintenance</span>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 13%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-user" v-tooltip.left="'Login As Client'" severity="info"
                                class="p-button-rounded p-button-info" @click="loginAsClientDialogOpen(data)" />
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-0"
                                @click="editClientDetailsRedirect(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add client dialog start here -->
    <Dialog v-model:visible="addUserDialogStatus" :style="{ width: '1100px' }" header="Add" :modal="true" class="p-fluid">
        <template #header>
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <span class="font-bold white-space-nowrap p-dialog-title" v-if="clientStepFlag == 0">Add Client</span>
                <span class="font-bold white-space-nowrap p-dialog-title" v-if="clientStepFlag == 1">Add Client
                    Config</span>
                <span class="font-bold white-space-nowrap p-dialog-title" v-if="clientStepFlag == 2">Add Client
                    Details</span>
            </div>
        </template>
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid" v-if="clientStepFlag == 0">
                <div class="p-field p-col-12 p-md-4">
                    <label for="clientname">
                        Client Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="clientname" class="p-text-capitalize" v-model.trim="clientname" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !clientname }" />
                    <small class="p-invalid p-error" v-if="v$.clientname.$error">{{
                        v$.clientname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="clientshortname">
                        Client Short Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="clientshortname" class="p-text-capitalize" v-model.trim="clientshortname" required="true"
                        autofocus :class="{ 'p-invalid': submitted && !clientshortname }" />
                    <small class="p-invalid p-error" v-if="v$.clientshortname.$error">{{
                        v$.clientshortname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="partyname">
                        Party Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="partyname" v-model="partyname" :options="partynameList" optionLabel="label"
                        placeholder="Select ..." appendTo="body" :filter="true" @change="selectclientcity"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.partyname.$error">{{ v$.partyname.$errors[0].$message
                    }}</small>
                </div>

                <div class="p-field p-col-12 p-md-4">
                    <label for="officemobileno">Office Contact Number <span class="p-invalid p-error">*</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            +91
                        </span>
                        <InputText id="officemobileno" v-model="officemobileno" autocomplete="off" maxlength="10"
                            @keypress="onlyNumber" autofocus :class="{ 'p-invalid': submitted && !officemobileno }" />
                    </div>

                    <small class="p-invalid p-error" v-if="v$.officemobileno.$error">{{
                        v$.officemobileno.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="officeemail">Office email ID <span class="p-invalid p-error">*</span></label>
                    <InputText id="email" v-model="officeemail" autocomplete="off" maxlength="60" autofocus
                        :class="{ 'p-invalid': submitted && !officeemail }" />
                    <small class="p-invalid p-error" v-if="v$.officeemail.$error">{{ v$.officeemail.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label" placeholder="Select ..."
                        appendTo="body" :filter="true" @change="selectclientcity"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{ v$.state.$errors[0].$message }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <!-- <AutoComplete id="city" v-model="city" :suggestions="citySuggestions" field="label"
                        placeholder="Select ..." appendTo="body" @complete="searchCities" multiple dropdown /> -->
                        <MultiSelect id="city" v-model="city" :options="cityList" optionLabel="label"
                                placeholder="Select Cities" mode="tags" :filter="true" class="multiselect-custom"
                                appendTo="body"></MultiSelect>
                    <small class="p-invalid p-error" v-if="v$.city.$error">{{ v$.city.$errors[0].$message }}</small>
                </div>

                <div class="p-field p-col-12 p-md-4">
                    <label for="lokSabha">
                        Lok Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <!-- <AutoComplete id="lokSabha" ref="loksabha" v-model="loksabha" :suggestions="loksabhaSuggestion"
                        field="label" placeholder="Select Lok Sabha" appendTo="body" @complete="searchLoksabha" multiple
                        dropdown /> -->
                        <MultiSelect id="loksabha" v-model="loksabha" :options="loksabhaList"
                                optionLabel="label" placeholder="Select Lok Sabha" mode="tags" :filter="true"
                                class="multiselect-custom" appendTo="body"></MultiSelect>
                    <small class="p-invalid p-error" v-if="v$.loksabha.$error">{{ v$.loksabha.$errors[0].$message }}</small>
                </div>

                <div class="p-field p-col-12 p-md-4">
                    <label for="vidhanSabha">
                        Vidhan Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <!-- <AutoComplete id="vidhanSabha" v-model="vidhansabha" :suggestions="vidhansabhaSuggestions" field="label"
                        placeholder="Select Vidhan Sabha" appendTo="body" @complete="searchVidhansabha" multiple dropdown /> -->
                        <MultiSelect id="vidhansabha" v-model="vidhansabha" :options="vidhansabhaList"
                                optionLabel="label" placeholder="Select Vidhan Sabha" mode="tags" :filter="true"
                                class="multiselect-custom" appendTo="body"></MultiSelect>
                    <small class="p-invalid p-error" v-if="v$.vidhansabha.$error">{{ v$.vidhansabha.$errors[0].$message
                    }}</small>
                </div>

                <div class="p-field p-col-12 p-md-4">
                    <label for="officeaddress">
                        Office Address
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Textarea id="officeaddress" class="p-text-capitalize" v-model="officeaddress" :autoResize="true"
                        rows="1" cols="30" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !officeaddress }" maxlength="250" />
                    <small class="p-invalid p-error" v-if="v$.officeaddress.$error">{{
                        v$.officeaddress.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Invoice Generator Application? <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="invoice_yes" name="option" v-bind:value="1" v-model="invoicegenerator"
                            :checked="invoicegenerator == 1" />
                        <label for="invoice_yes" class="p-mr-2">Yes</label>
                        <RadioButton id="invoice_no" name="option" v-bind:value="0" v-model="invoicegenerator"
                            :checked="invoicegenerator == 0" />
                        <label for="invoice_no" class="p-mr-2">No</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.invoicegenerator.$error">{{
                        v$.invoicegenerator.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="clientstatus">
                        Client Status
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="clientstatus" v-model="clientstatus" :options="clientstatusList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.clientstatus.$error">{{
                        v$.clientstatus.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-12">
                            <label for="image">
                                Party Logo
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <FileUpload mode="basic" ref="clientlogo" :maxFileSize="1000000" accept="image/*"
                                chooseLabel="Upload Party Logo" auto @select="handleClientLogoUpload" />
                            <small class="p-invalid p-error" v-if="clientlogoerror">{{ clientlogoerror }}</small>
                            <img v-if="clientlogoUrl && clientlogoUrl.url" class="p-pt-2" role="presentation"
                                :alt="clientlogoUrl.name" :src="clientlogoUrl.url" width="120" height="120" />

                            <small class="p-invalid p-error" v-if="clientlogoerror">{{ clientlogoerror
                            }}</small>
                            <small class="p-invalid p-error" v-if="v$.clientlogo.$error">{{
                                v$.clientlogo.$errors[0].$message
                            }}</small>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="image">Party Leader Logo
                        <span class="p-invalid p-error" v-if="this.partyleaderlogo == null || this.partyleaderlogo == ''">*</span>
                    </label>
                    <FileUpload v-model="partylogo" mode="basic" ref="partylogo" accept="image/*" :maxFileSize="1000000"
                        chooseLabel="Upload Party Leader Logo" auto @select="handlePartyLogoUpload" />
                    <img v-if="partyLogoUrl && partyLogoUrl.url" class="p-pt-2" role="presentation" :alt="partyLogoUrl.name"
                        :src="partyLogoUrl.url" width="120" height="120" />
                    <small class="p-invalid p-error" v-if="partylogoerror">{{ partylogoerror }}</small>
                    <small class="p-invalid p-error" v-if="v$.partylogo.$error">{{ v$.partylogo.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="image">Party Sub-Leader Logo
                        <span class="p-invalid p-error" v-if="this.partylogo == null || this.partylogo == ''">*</span>
                    </label>
                    <FileUpload v-model="partyleaderlogo" mode="basic" ref="partyleaderlogo" accept="image/*"
                        :maxFileSize="1000000" chooseLabel="Upload Party Sub-Leader Logo" auto
                        @select="handlePartyleaderLogoUpload" />
                    <img v-if="partyleaderLogoUrl && partyleaderLogoUrl.url" class="p-pt-2" role="presentation"
                        :alt="partyleaderLogoUrl.name" :src="partyleaderLogoUrl.url" width="120" height="120" />
                    <small class="p-invalid p-error" v-if="partyleaderlogoerror">{{ partyleaderlogoerror }}</small>
                    <small class="p-invalid p-error" v-if="v$.partyleaderlogo.$error">{{
                        v$.partyleaderlogo.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="image">Party Banner
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <FileUpload v-model="partybanner" mode="basic" ref="partybanner" accept="image/*" :maxFileSize="1000000"
                        chooseLabel="Upload Party Banner" auto @select="handlePartybannerUpload" />
                    <img v-if="partybannerUrl && partybannerUrl.url" class="p-pt-2" role="presentation"
                        :alt="partybannerUrl.name" :src="partybannerUrl.url" width="120" height="120" />
                    <small class="p-invalid p-error" v-if="partybannererror">{{ partybannererror }}</small>
                    <small class="p-invalid p-error" v-if="v$.partybanner.$error">{{ v$.partybanner.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="image">Access Code Banner Image
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <FileUpload v-model="accesscodebannerimg" mode="basic" ref="accesscodebannerimg" accept="image/*"
                        :maxFileSize="1000000" chooseLabel="Upload Access Code Banner" auto
                        @select="handleaccesscodebannerimgUpload" />
                    <img v-if="accesscodebannerimgUrl && accesscodebannerimgUrl.url" class="p-pt-2" role="presentation"
                        :alt="accesscodebannerimgUrl.name" :src="accesscodebannerimgUrl.url" width="120" height="120" />
                    <small class="p-invalid p-error" v-if="accesscodebannerimgerror">{{ accesscodebannerimgerror }}</small>
                    <small class="p-invalid p-error" v-if="v$.accesscodebannerimg.$error">{{
                        v$.accesscodebannerimg.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="image">Party Favicon</label>
                    <FileUpload v-model="partyfavicon" mode="basic" ref="partyfavicon" accept="image/*"
                        :maxFileSize="1000000" chooseLabel="Upload Favicon" auto @select="handlePartyfaviconUpload" />
                    <img v-if="partyfaviconUrl && partyfaviconUrl.url" class="p-pt-2" role="presentation"
                        :alt="partyfaviconUrl.name" :src="partyfaviconUrl.url" width="120" height="120" />
                    <small class="p-invalid p-error" v-if="partyfaviconerror">{{ partyfaviconerror }}</small>
                    <!-- <small class="p-invalid p-error" v-if="v$.partyfavicon.$error">{{ v$.partyfavicon.$errors[0].$message
                    }}</small> -->
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" v-if="clientStepFlag == 1">
                <div class="p-field p-col-12 p-md-4">
                    <label for="accesscode">
                        Access Code
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="accesscode" v-model.trim="accesscode" required="true" maxlength="25" autofocus
                        :class="{ 'p-invalid': submitted && !accesscode }" />
                    <small class="p-invalid p-error" v-if="v$.accesscode.$error">{{
                        v$.accesscode.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="storagebucketid">
                        Storage Bucket Id
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="storagebucketid" v-model.trim="storagebucketid" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !storagebucketid }" />
                    <small class="p-invalid p-error" v-if="v$.storagebucketid.$error">{{
                        v$.storagebucketid.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="systemurl">
                        System URL
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="systemurl" v-model.trim="systemurl" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !systemurl }" />
                    <small class="p-invalid p-error" v-if="v$.systemurl.$error">{{
                        v$.systemurl.$errors[0].$message }}</small>
                    <small class="p-invalid p-error" v-if="systemUrlErr">{{ systemUrlErr }}</small>
                </div>

                <div class="p-field p-col-12 p-md-4">
                    <label for="databasename">
                        Client Database Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="databasename" class="p-text-capitalize" v-model.trim="databasename" required="true"
                        autofocus :class="{ 'p-invalid': submitted && !databasename }" />
                    <small class="p-invalid p-error" v-if="v$.databasename.$error">{{
                        v$.databasename.$errors[0].$message
                    }}</small>
                </div>

            </div>
            <div class="p-fluid p-formgrid p-grid" v-if="clientStepFlag == 2">
                <div class="p-field p-col-12 p-md-4">
                    <label for="emailfromname">
                        Email From Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="emailfromname" class="p-text-capitalize" v-model.trim="emailfromname" required="true"
                        autofocus :class="{ 'p-invalid': submitted && !emailfromname }" />
                    <small class="p-invalid p-error" v-if="v$.emailfromname.$error">{{
                        v$.emailfromname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="emailfromid">
                        Transactional Email From Id
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="emailfromid" v-model.trim="emailfromid" required="true" autofocus
                        :class="{ 'p-invalid': submitted && !emailfromid }" />
                    <small class="p-invalid p-error" v-if="v$.emailfromid.$error">{{
                        v$.emailfromid.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>Billing Type <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="prepaid_type" name="option" v-bind:value="1" v-model="billingtype"
                            :checked="billingtype == 1" />
                        <label for="prepaid_type" class="p-mr-2">Prepaid</label>
                        <RadioButton id="postpaid_type" name="option" v-bind:value="2" v-model="billingtype"
                            :checked="billingtype == 2" />
                        <label for="postpaid_type" class="p-mr-2">Postpaid</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.billingtype.$error">{{ v$.billingtype.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label>License Type <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="type_exclusive" name="option" v-bind:value="1" v-model="licensetype"
                            :checked="licensetype == 1" />
                        <label for="type_exclusive" class="p-mr-2">Non-exclusive</label>
                        <RadioButton id="type_non_exclusive" name="option" v-bind:value="2" v-model="licensetype"
                            :checked="licensetype == 2" />
                        <label for="type_non_exclusive" class="p-mr-2">Exclusive</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.licensetype.$error">{{ v$.licensetype.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div v-if="clientStepFlag == 0">
                <Button v-if="!showLoader" label="Continue" icon="pi pi-arrow-right" class="p-button-text p-ml-auto"
                    @click="saveClientStepFirst($event)" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
            <div v-if="clientStepFlag == 1">
                <Button v-if="!showLoader" label="Continue" icon="pi pi-arrow-right" class="p-button-text p-ml-auto"
                    @click="saveClientStepSecond" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
            <div v-if="clientStepFlag == 2">
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="saveClientStepThird" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add client dialog end here -->

    <!--Login as client Dialog starts here -->
    <Dialog v-model:visible="loginAsClientDialogStatus" :style="{ width: '70vw' }" modal class="p-fluid"
        header="Client Login">
        <div style="position: relative;" v-if="!showLoader">
            <div class="p-pt-2 p-px-5">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-d-flex p-field p-col-6 p-md-12">
                        <h6 class="p-m-0 ">Select Employee:</h6>
                        <Dropdown id="select-emp" class="p-ml-5 p-text-capitalize" v-model="clientEmployees" :options="clientEmplist"
                            optionLabel="label" placeholder="Select Employee" emptyMessage="No Records" appendTo="body"
                            style="width: 30vw" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!showLoader" :disabled="clientEmployees == ''" label="Login" icon="pi pi-check"
                class="p-button-text p-ml-auto" style="width: 90px;" @click="loginAsClientRequest"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>

    <!-- Login as client Dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { required, helpers, email, numeric, maxLength, minLength, requiredIf } from '@vuelidate/validators';
import moment from 'moment';

export default {
    data() {
        return {
            v$: useValidate(),
            clientsList: [],
            page_no: 0,
            totalRecords: 0,
            selectedusers: null,
            loading: false,
            expandedRows: [],
            addUserDialogStatus: false,
            clientStepFlag: 0,
            showLoader: false,
            partyname: '',
            partyList: '',
            systemtier: '',
            systemTierList: [
                { label: 'Client', value: 1 },
                { label: 'State', value: 2 },
                { label: 'National Level', value: 3 },
            ],
            clientname: '',
            clientshortname: '',
            partylogo: '',
            partyLogoUrl: '',
            partylogoerror: '',
            partyleaderlogo: '',
            partyleaderLogoUrl: '',
            partyleaderlogoerror: '',
            clientlogo: '',
            clientlogoUrl: '',
            clientlogoerror: '',
            partybanner: '',
            partybannerUrl: '',
            partybannererror: '',
            accesscodebannerimg: '',
            accesscodebannerimgUrl: '',
            accesscodebannerimgerror: '',
            partyfavicon: '',
            partyfaviconUrl: '',
            partyfaviconerror: '',

            officeaddress: '',
            state: '',
            stateList: [],
            partynameList: [],
            city: '',
            cityList: [],
            citySuggestions: [],
            loksabha: '',
            loksabhaList: [],
            loksabhaSuggestion: [],
            vidhansabha: '',
            vidhansabhaList: [],
            vidhansabhaSuggestions: [],
            countrycode: { label: '+91', value: 101 },
            countryCodeList: [
                { label: '+91', value: 101 }
            ],
            officemobileno: '',
            officeemail: '',
            invoicegenerator: 1,
            systemtype: '',
            accesscode: '',
            storagebucketid: '',
            billingtype: '',
            licensetype: '',
            clientstatus: { label: 'Active', value: 0 },
            clientstatusList: [
                { label: 'Active', value: 0 },
                { label: 'Soft Suspension', value: 1 },
                { label: 'Temporary Suspension', value: 2 },
                { label: 'Suspended', value: 3 },
                { label: 'Deletion Notice Period', value: 4 },
                { label: 'Permanent Suspended', value: 5 },
                { label: 'Deleted', value: 6 },
                { label: 'Under Maintenance', value: 7 },
            ],
            systemurl: '',
            databasename: '',
            databaseid: '',
            emailfromname: '',
            emailfromid: '',
            databasepassword: '',
            submitted: false,
            client_id: '',
            clientlogoUrlPath: '',
            storage_path: '',
            systemUrlErr: '',
            loginAsClientDialogStatus: false,
            clientEmplist: [],
            clientEmployees: '',
            loginsystemurl: '',
            clientbmsurl: '',
        };
    },
    validations() {
        if (this.clientStepFlag == 0) {
            return {
                clientname: { required: helpers.withMessage('Please enter client name', required) },
                clientshortname: { required: helpers.withMessage('Please enter client short name', required) },
                partyname: { required: helpers.withMessage('Please select party name', required) },
                clientlogo: { required: helpers.withMessage('Please upload party logo', required) },
                partybanner: { required: helpers.withMessage('Please upload party banner', required) },
                //partylogo: { required: helpers.withMessage('Please upload party leader logo', required) },
                //partyleaderlogo: { required: helpers.withMessage('Please upload sub leader logo', required) },
                partylogo: {
                    requiredIf: helpers.withMessage('Please upload party leader logo', requiredIf(this.partyleaderlogo == null || this.partyleaderlogo == '')),
                },
                partyleaderlogo: {
                    requiredIf: helpers.withMessage('Please upload party sub-leader logo', requiredIf(this.partylogo == null || this.partylogo == '')),
                },
                accesscodebannerimg: { required: helpers.withMessage('Please upload  access code banner image', required) },
                officeaddress: { required: helpers.withMessage('Please enter office address', required) },
                state: { required: helpers.withMessage('Please select state', required) },
                city: { required: helpers.withMessage('Please select city', required) },
                loksabha: { required: helpers.withMessage('Please select loksabha', required) },
                vidhansabha: { required: helpers.withMessage('Please select vidhansabha', required) },
                officeemail: { required: helpers.withMessage('Please enter email address', required), email: helpers.withMessage('Email address is invalid', email) },
                officemobileno: { required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                invoicegenerator: { required: helpers.withMessage('Please select option', required) },
                clientstatus: { required: helpers.withMessage('Please select client status', required) },
            }
        } else if (this.clientStepFlag == 1) {
            return {
                accesscode: { required: helpers.withMessage('Please enter access code', required) },
                storagebucketid: { required: helpers.withMessage('Please enter storage bucket Id', required) },
                systemurl: { required: helpers.withMessage('Please enter system URL', required) },
                databasename: { required: helpers.withMessage('Please enter client database name', required) },
            };
        } else if (this.clientStepFlag == 2) {
            return {
                // systemtier: { required: helpers.withMessage('Please select system tier', required) },
                // systemtype: { required: helpers.withMessage('Please select system type', required) },
                billingtype: { required: helpers.withMessage('Please select billing type', required) },
                licensetype: { required: helpers.withMessage('Please select license type', required) },
                emailfromname: { required: helpers.withMessage('Please enter email from name', required) },
                emailfromid: { required: helpers.withMessage('Please enter email from id', required), email: helpers.withMessage('Email address is invalid', email) },
            };
        }

    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.clientlist();
        this.getStates();
        this.getParties();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
    },
    watch: {
        loksabha(newValue) {
            if (newValue) {
                this.getVidhanSabhaList({ value: newValue.map(d => d.value).join() })
            }
        },
        systemurl(systemurl) {
            this.errmsg = "";
            this.validateUrl(systemurl);
            if (systemurl.length === 0) {
                this.systemUrlErr = "";
                this.errStatus = false;
            }
        },
    },
    methods: {
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getParties(ev) {
            this.ApiService.getParties(ev).then((data) => {
                if (data.status == 200) {
                    this.partynameList = data.data;
                } else {
                    this.partynameList = '';
                }
            });
        },

        selectclientcity(ev) {
            this.city = [];
            this.loksabha = '';
            this.vidhansabha = '';
            this.vidhansabhaList =[];
            this.getCity(ev.value);
            this.getLokSabhaList(ev.value);
        },

        getCity(e) {
            if (e.value) {
                this.ApiService.getcities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.cityList = items.data;
                    }
                });
            }
        },
        getLokSabhaList(ev) {
            this.ApiService.getloksabha({ mbb4: ev.value })
                .then((items) => {
                    if (items.success === true) {
                        this.loksabhaList = items.data;
                    }
                });
        },
        getVidhanSabhaList(ev) {
            this.vidhansabha = '';
            this.ApiService.getvidhansabha({ district_id: ev.value })
                .then((items) => {
                    if (items.success === true) {
                        this.vidhansabhaList = items.data;
                    }
                });
        },
        clientlist(ev) {
            this.loading = true;
            this.ApiService.clientlist(ev).then(({ data, status }) => {
                if (status == 200) {
                    this.clientsList = data.result;
                    this.totalRecords = data.count;
                } else {
                    this.clientsList = '';
                }
                this.loading = false;
            });
        },
        saveClientStepFirst() {
            this.submitted = true;
            this.v$.$validate();
            var formData = new FormData();
            formData.append('maa2', this.clientname);
            formData.append('maa3', this.partyname.value);
            formData.append('maa4', this.partyname.label);
            formData.append('maa8', this.state.value);
            formData.append('maa9', this.state.label);
            formData.append('maa6', this.clientlogo);
            formData.append('maa47', this.partylogo);
            formData.append('maa66', this.partyleaderlogo);
            formData.append('maa7', this.officeaddress);
            if(this.city != '' && this.city != null){
                let selectedCitiesValues = this.city.map(d => d.value).join();
                let selectedCitiesLabels = this.city.map(d => d.label).join();
                formData.append('maa11', selectedCitiesValues);
                formData.append('maa12', selectedCitiesLabels);
            }
            if(this.loksabha != '' && this.loksabha != null){
                let selectedLoksabhaValues = this.loksabha.map(d => d.value).join();
                let selectedLoksabhaLabels = this.loksabha.map(d => d.label).join();
                formData.append('maa13', selectedLoksabhaValues);
                formData.append('maa14', selectedLoksabhaLabels);
            }
            if(this.vidhansabha != '' && this.vidhansabha != null){
                let selectedVidhansabhaValues = this.vidhansabha.map(d => d.value).join();
                let selectedVidhansabhaLabels = this.vidhansabha.map(d => d.label).join();
                formData.append('maa15', selectedVidhansabhaValues);
                formData.append('maa16', selectedVidhansabhaLabels);
            }
            formData.append('maa17', this.officemobileno);
            formData.append('maa18', this.officeemail);
            formData.append('maa20', this.invoicegenerator);
            formData.append('maa37', this.clientstatus.value);
            formData.append('maa59', this.partybanner);
            formData.append('maa61', this.partyfavicon);
            formData.append('maa63', this.accesscodebannerimg);
            formData.append('maa65', this.clientshortname);

            if (!this.v$.$error) {
                this.loading = true;
                this.ApiService.createclient(formData).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.submitted = false;
                        var successMsg = items.message;
                        this.clientStepFlag = 1;
                        this.client_id = items.data.maa1;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });

                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
                return this.clientlist({ page_no: this.page_no });
            }
        },
        saveClientStepSecond() {

            this.v$.$validate();
            this.submitted = true;

            if (!this.v$.$error) {

                this.loading = true;

                var formData = new FormData();
                formData.append('maa1', this.client_id)
                formData.append('maa32', this.accesscode);
                formData.append('maa24', this.storagebucketid);
                formData.append('maa25', this.systemurl);
                formData.append('maa29', this.databasename);

                this.ApiService.saveclientsstep(formData).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        // this.addUserDialogStatus = false;
                        this.submitted = false;
                        this.clientStepFlag = 2;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });

                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        saveClientStepThird() {

            this.v$.$validate();
            this.submitted = true;

            if (!this.v$.$error) {

                this.loading = true;

                var formData = new FormData();
                formData.append('maa1', this.client_id)
                formData.append('maa23', this.emailfromname);
                formData.append('maa22', this.emailfromid);
                formData.append('maa33', this.billingtype);
                formData.append('maa36', this.licensetype);

                this.ApiService.saveclientsstepthird(formData).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.addUserDialogStatus = false;
                        this.clientStepFlag = 3;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.clientlist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        handlePartyLogoUpload() {
            this.partylogo = this.$refs.partylogo.files[0];
            var partylogo = this.$refs.partylogo.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partylogo) {
                this.partylogoerror = 'Please upload logo';
            } else if (!partylogo.size > 2048) {
                this.partylogoerror = 'File size cannot exceed more than 2MB';
            } else if (!partylogo.name.match(allowedExtensions)) {
                this.partylogoerror = 'Invalid file type';
            } else {
                this.partylogoerror = '';
                this.partyLogoUrl = { name: this.partylogo.name, url: this.partylogo.objectURL };
            }
        },

        handlePartyleaderLogoUpload() {
            this.partyleaderlogo = this.$refs.partyleaderlogo.files[0];
            var partyleaderlogo = this.$refs.partyleaderlogo.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partyleaderlogo) {
                this.partyleaderlogoerror = 'Please upload logo';
            } else if (!partyleaderlogo.size > 2048) {
                this.partyleaderlogoerror = 'File size cannot exceed more than 2MB';
            } else if (!partyleaderlogo.name.match(allowedExtensions)) {
                this.partyleaderlogoerror = 'Invalid file type';
            } else {
                this.partyleaderlogoerror = '';
                this.partyleaderLogoUrl = { name: this.partyleaderlogo.name, url: this.partyleaderlogo.objectURL };
            }
        },

        handleClientLogoUpload(e) {
            this.clientlogo = e.files[0];
            var clientlogo = e.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!clientlogo) {
                this.clientlogoerror = 'Please upload logo';
            } else if (!clientlogo.size > 2048) {
                this.clientlogoerror = 'File size cannot exceed more than 2MB';
            } else if (!clientlogo.name.match(allowedExtensions)) {
                this.clientlogoerror = 'Invalid file type';
            } else {
                this.clientlogoerror = '';
                this.clientlogoUrl = { name: this.clientlogo.name, url: this.clientlogo.objectURL };
            }
        },
        handlePartybannerUpload() {
            this.partybanner = this.$refs.partybanner.files[0];
            var partybanner = this.$refs.partybanner.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partybanner) {
                this.partybannererror = 'Please upload logo';
            } else if (!partybanner.size > 2048) {
                this.partybannererror = 'File size cannot exceed more than 2MB';
            } else if (!partybanner.name.match(allowedExtensions)) {
                this.partybannererror = 'Invalid file type';
            } else {
                this.partybannererror = '';
                this.partybannerUrl = { name: this.partybanner.name, url: this.partybanner.objectURL };
            }
        },
        handleaccesscodebannerimgUpload() {
            this.accesscodebannerimg = this.$refs.accesscodebannerimg.files[0];
            var accesscodebannerimg = this.$refs.accesscodebannerimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!accesscodebannerimg) {
                this.accesscodebannerimgerror = 'Please upload logo';
            } else if (!accesscodebannerimg.size > 5120) {
                this.accesscodebannerimgerror = 'File size cannot exceed more than 5MB';
            } else if (!accesscodebannerimg.name.match(allowedExtensions)) {
                this.accesscodebannerimgerror = 'Invalid file type';
            } else {
                this.accesscodebannerimgerror = '';
                this.accesscodebannerimgUrl = { name: this.accesscodebannerimg.name, url: this.accesscodebannerimg.objectURL };
            }
        },
        handlePartyfaviconUpload() {
            this.partyfavicon = this.$refs.partyfavicon.files[0];
            var partyfavicon = this.$refs.partyfavicon.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!partyfavicon) {
                this.partyfaviconerror = 'Please upload logo';
            } else if (!partyfavicon.size > 1024) {
                this.partyfaviconerror = 'File size cannot exceed more than 1MB';
            } else if (!partyfavicon.name.match(allowedExtensions)) {
                this.partyfaviconerror = 'Invalid file type';
            } else {
                this.partyfaviconerror = '';
                this.partyfaviconUrl = { name: this.partyfavicon.name, url: this.partyfavicon.objectURL };
            }
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.clientlist({ page_no: event.page });
        },
        addNewUserDialogOpen() {
            this.submitted = false;
            this.addUserDialogStatus = true;
            this.client_id = '';
            this.v$.$reset();
        },
        loginAsClientDialogOpen(data) {
            this.submitted = false;
            this.clientEmplist=[];
            this.loginAsClientDialogStatus = true;
            this.client_id = data.maa1;
            this.loginsystemurl = data.maa25;
            this.v$.$reset();
            this.getClientUsersOnly();
        },
        getClientUsersOnly() {
            this.ApiService.getClientUsersOnly({ clientId: this.client_id }).then((data) => {
                if (data.success === true) {
                    this.clientEmplist = data.data;
                }
            });
        },
        loginAsClientRequest() {
            this.id = this.client_id;
            this.meraneta_url = this.loginsystemurl;
            // this.clientbmsurl = 'http://' + this.loginsystemurl + '/clientLogin';
            // this.clientbmsurl = "https://testbjpka.nextedgecivictech.in/dashboard";
            // window.open(this.clientbmsurl, "_blank");
            this.isSubmitted = true;
            this.v$.$validate();
            var fields = {};
            fields["client_id"] = this.client_id;
            fields["meraneta_url"] = this.meraneta_url;
            fields["userId"] = this.clientEmployees.value;
            fields["userlabel"] = this.clientEmployees.label;
           
            this.ApiService.getLoginAsClient(fields).then((items) => {
                if (items.status == 200) {
                    this.showLoader = false;
                    var successMsg = items.message;
                    if(items.url){
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        window.open(items.url, '_blank');
                    }
                }
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (!this.officemobileno && !+$event.key) {
                $event.preventDefault();
            }
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        editClientDetailsRedirect(data) {
            router.push({
                name: "clientdashboard",
                params: { clientId: data.maa1 },
            });
            // // window.open(routeData.href, "_self");
        },
        validateUrl(url) {
            this.errStatus = false;
            if (!this.validUrl(url)) {
                this.systemUrlErr = "Please enter a valid URL";
                this.errStatus = true;
            }
            if (this.errStatus === false) {
                this.systemUrlErr = "";
                return true;
            }
        },

        validUrl: function (url) {
            // var re = /^((?!ftp|http(s?)):)?\/\/[^ "]+(\.[a-zA-Z]{2,})+$/;
            // var re = /^[^ "]+(\.[a-zA-Z]{2,})+$/;
            var re = /^(?!((ftp|http(s?)):\/\/))?[^ "]+(\.[a-zA-Z]{2,})+$/;
            return re.test(url);
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value);
                return moment(localTime).format("DD/MM/YYYY");
            } else {
                return '-';
            }
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

.p-button {
    margin-right: 0.5rem;
}

.p-overlay-badge {
    margin-right: 2rem;
}

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

.p-datatable .p-column-filter {
    display: none;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-users) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead>tr>th {
        text-align: left;
    }

    .p-datatable-tbody>tr>td {
        cursor: auto;

        &:last-child {
            text-align: center;
        }
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}

/* Responsive */
.p-datatable-users .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.orders-subtable {
    padding: 1rem;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-users {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid var(--surface-d);

                >td {
                    text-align: left !important;
                    display: block;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                        display: inline-block;
                        width: 60%;
                    }

                    .p-rating {
                        display: inline-block;
                    }
                }
            }

            .p-datatable-tbody>tr.p-rowgroup-footer {
                display: flex;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.document-received-image {
    max-width: 80px;
    width: 100%;
    display: block;
    cursor: pointer;
}

.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
